import React from 'react'
import styled from 'styled-components'
import App from 'App'
import { CONTAINER_MAX_WIDTH } from 'consts'
import { COLOR_CONSTANTS, radius, space } from 'theme'
import SEO from 'components/SEO'
import { Box, Flex, Grid } from 'components/Layout'
import { H2, H1, Text } from 'components/Typography'
import Container from 'components/Container'
import Footer from 'components/Footer'
import LocalImage from 'components/LocalImage'
import G2FeaturesComponent from 'components/G2FeaturesComponent'

import imageHeader from 'static/images/customers/header_picture.webp'

import usersLoveUs from 'static/images/g2_badges/users-love-us.svg'
import highPerformer from 'static/images/g2_badges/high_performer_winter_2023.svg'
import smallBiz from 'static/images/g2_badges/high_performer_small_business_winter_2023.svg'
import FeaturedStoriesSection from './components/FeaturedStoriesSection'
import SocialMediasSection from '../Home/components/SocialMediasSection'
import CarouselReviewSection from '../Home/components/CarouselReviewSection'

const BadgeImg = styled.img`
  padding-right: ${space.xs};
  padding-left: ${space.xs};
  cursor: pointer;
  width: 110px;
`

const Wrapper = styled(Flex)`
  position: relative;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  overflow-x: hidden;
`

const StyledHeaderWrapper = styled(Wrapper)`
  overflow: hidden;
`

const Customers = () => {
  return (
    <App fullHeader>
      <SEO
        title="Customers and Case Studies | Vista Social"
        description="Who uses Vista Social and what results did they achieve? Read Case Studies from brands like yours to see how Vista Social helped them reach their social goals."
        path="/customers/"
      />

      <StyledHeaderWrapper>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <Flex mb="l" flexDirection="column" alignItems="center">
            <Grid
              mt="xl"
              pt={{ mobile: 0, desktop: 'xs' }}
              gridTemplateColumns={{ mobile: '1fr', desktop: '1fr 1fr' }}
              gridGap="l"
              width="100%"
              alignItems="center"
            >
              <Flex width="100%" justifyContent="center" alignItems="center">
                <Flex flexDirection="column">
                  <Flex width="100%" justifyContent="center" flexDirection="column">
                    <Flex flexDirection={{ mobile: 'row', desktop: 'column' }} flexWrap="wrap" justifyContent="center">
                      <H1
                        fontSize="4xl"
                        fontWeight="bold"
                        color={COLOR_CONSTANTS.DENIM}
                        textAlign={{ mobile: 'center', desktop: 'left' }}
                      >
                        <H1
                          as="span"
                          fontSize="4xl"
                          fontWeight="bold"
                          color="primary"
                          textAlign={{ mobile: 'center', desktop: 'left' }}
                        >
                          Customer Success{' '}
                        </H1>
                        <br />
                        is our Priority
                      </H1>
                    </Flex>
                    <H2
                      mt="m"
                      color="secondaryText"
                      fontSize="l"
                      textAlign={{ mobile: 'center', desktop: 'left' }}
                      fontWeight="normal"
                      lineHeight="160%"
                    >
                      At Vista Social, what matters most are the business owners, agencies, brands, causes and
                      communities that trust us to help them succeed. See how big brands and growing businesses find
                      social media success with Vista Social!
                    </H2>
                  </Flex>
                </Flex>
              </Flex>
              <Flex
                width="100%"
                ml="auto"
                mr={{ mobile: 'auto', desktop: 0 }}
                mt={{ mobile: 'l', desktop: 0 }}
                position="relative"
              >
                <Box
                  position="absolute"
                  borderRadius={radius.pill}
                  bg={COLOR_CONSTANTS.SALT}
                  width="154px"
                  height="154px"
                  top="-25px"
                  left="-60px"
                  zIndex="-1"
                />
                <Box
                  position="absolute"
                  borderRadius={radius.pill}
                  bg={COLOR_CONSTANTS.SALT}
                  width="210px"
                  height="210px"
                  bottom="-90px"
                  right="-110px"
                  zIndex="-1"
                />
                <LocalImage
                  src={imageHeader}
                  width="100%"
                  mx="auto"
                  maxWidth="540px"
                  alt="Vista Social customer stories"
                />
              </Flex>
            </Grid>
            <Flex mt="m" pt="m" flexDirection="column" alignItems="center" mb={{ mobile: 'l', desktop: 'l' }}>
              <G2FeaturesComponent />
            </Flex>
          </Flex>
        </Container>
      </StyledHeaderWrapper>

      <Box mt="s" />

      <FeaturedStoriesSection />

      <SocialMediasSection />

      <CarouselReviewSection />

      <Footer />
    </App>
  )
}

export default Customers
