import React, { Fragment } from 'react'
import styled from 'styled-components'
import ReactStars from 'react-stars'
import { CONTAINER_MAX_WIDTH } from 'consts'
import { COLOR_CONSTANTS, radius, colors, space } from 'theme'
import { Flex, Box } from 'components/Layout'
import { Text, H3 } from 'components/Typography'
import Container from 'components/Container'
import LocalImage from 'components/LocalImage'

import carouselDoubleArrowRightIcon from 'static/images/home/carousel/double_arrow_right.svg'

import imageStory1Person from 'static/images/customers/featured_stories/story_1_person.svg'
import imageStory1Logo from 'static/images/customers/featured_stories/story_1_logo.svg'
import imageStory1Image1 from 'static/images/customers/featured_stories/story_1_image_1.svg'

import imageStory2Person from 'static/images/customers/featured_stories/story_2_person.svg'
import imageStory2Logo from 'static/images/customers/featured_stories/story_2_logo.svg'
import imageStory2Image1 from 'static/images/customers/featured_stories/story_2_image_1.svg'
import imageStory2Image2 from 'static/images/customers/featured_stories/story_2_image_2.svg'

import imageStory3Person from 'static/images/customers/featured_stories/story_3_person.svg'
import imageStory3Logo from 'static/images/customers/featured_stories/story_3_logo.svg'
import imageStory3Image1 from 'static/images/customers/featured_stories/story_3_image_1.svg'
import imageStory3Image2 from 'static/images/customers/featured_stories/story_3_image_2.svg'

import imageStory4Person from 'static/images/customers/featured_stories/story_4_person.png'
import imageStory4Logo from 'static/images/customers/featured_stories/story_4_logo.png'
import imageStory4Image1 from 'static/images/customers/featured_stories/story_4_image_1.svg'
import imageStory4Image2 from 'static/images/customers/featured_stories/story_4_image_2.svg'

import clickit from 'static/images/customers/featured_stories/clickit.svg'
import zack from 'static/images/customers/featured_stories/zackary.svg'

const Wrapper = styled(Flex)`
  position: relative;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  background: linear-gradient(0deg, #0250c9 0%, #003881 100%);
  overflow-x: hidden;
`

const StyledStoryWrapper = styled(Flex)`
  flex-direction: column;
  text-align: left;
  background-color: ${COLOR_CONSTANTS.WHITE};
  border-radius: ${radius.xxxl};
  padding: ${space.xl};
  z-index: 1;
`

const StyledStoryPersonImage = styled(LocalImage)`
  border-radius: ${radius.pill};
`

const StyledReadCaseStudyText = styled(Text)`
  cursor: pointer;
  &:hover {
    color: ${COLOR_CONSTANTS.WHITE};
    text-decoration: underline;
  }
`

const STORIES = [
  {
    storyTitle: 'How A Leading Marketing Agency Increased Their Impressions By 244% With Vista Social',
    title: 'ClickIt Social',
    description: (
      <Text mt="l" color={COLOR_CONSTANTS.DENIM} fontSize="l">
        ”Our clients love to see engagement and interactions but we have a unique style of formatting posts,
        specifically with Facebook, that really drive the lick to web traffic for our clients and{' '}
        <Text as="span" color="primary" fontSize="l">
          {' '}
          Vista Social allows us to do just that without any sacrifices
        </Text>
        ”
      </Text>
    ),
    person: {
      logo: zack,
      name: "Zackary O'Keefe",
      position: `Marketing Director at ClickIt Social`,
      count: 5,
    },
    companyLogo: clickit,
    readCaseStudyUrl:
      'https://vistasocial.com/insights/how-clickit-social-increased-their-impressions-with-vista-social/',
    backgroundFragment: (
      <Fragment>
        <Box position="absolute" top="70px" right="183px" zIndex="1">
          <LocalImage source={imageStory4Image1} />
        </Box>
        <Box position="absolute" bottom="-25px" left="-25px">
          <LocalImage source={imageStory4Image2} />
        </Box>
        <Box position="absolute" top="170px" right="-95px">
          <LocalImage source={imageStory2Image2} />
        </Box>
      </Fragment>
    ),
  },
  {
    storyTitle: 'How A Top-Rated Instagram Agency Found Success with Vista Social',
    title: 'Wheeler Marketing',
    description: (
      <Text mt="l" color={COLOR_CONSTANTS.DENIM} fontSize="l">
        ”As an agency owner, I’m always looking for new tools that allow WMA to plan, collaborate, and create content
        both for the agency and for our clients in the most efficient way possible. In the past we’ve used Loomly,
        Agorapulse, Later, Buffer, Hootsuite, Planoly, Tailwind, and more and I’ve got to say{' '}
        <Text as="span" color="primary" fontSize="l">
          Vista Social has exactly what I’ve been looking for.
        </Text>
        ”
      </Text>
    ),
    person: {
      logo: imageStory4Person,
      name: 'Danielle Wheeler',
      position: `CEO at Wheeler Marketing Agency`,
      count: 5,
    },
    companyLogo: imageStory4Logo,
    readCaseStudyUrl:
      'https://vistasocial.com/insights/how-a-top-rated-instagram-agency-found-success-with-vista-social/',
    backgroundFragment: (
      <Fragment>
        <Box position="absolute" top="70px" right="183px" zIndex="1">
          <LocalImage source={imageStory4Image1} />
        </Box>
        <Box position="absolute" bottom="-25px" left="-25px">
          <LocalImage source={imageStory4Image2} />
        </Box>
        <Box position="absolute" top="170px" right="-95px">
          <LocalImage source={imageStory2Image2} />
        </Box>
      </Fragment>
    ),
  },
  {
    storyTitle: 'Why A Consulting Agency Switched From ContentCal to Vista Social',
    title: 'QueenBee Consulting',
    description: (
      <Text mt="l" color={COLOR_CONSTANTS.DENIM} fontSize="l">
        ”My clients are seeing at least 70% of their sales from social media alone, so it is imperative that we keep
        posting there and{' '}
        <Text as="span" color="primary" fontSize="l">
          Vista Social is a major part of that.
        </Text>
        ” Danelle trialed many social media management tools on the market to support her growing needs and struggled to
        find one that was a perfect fit. From Hootsuite to ContentCal to Vista Social, Queen Bee Consulting finally
        found social media success.
      </Text>
    ),
    person: {
      logo: imageStory1Person,
      name: 'Danelle Brown',
      position: `President of Queen Bee Consulting`,
      count: 5,
    },
    companyLogo: imageStory1Logo,
    readCaseStudyUrl:
      'https://vistasocial.com/insights/why-a-consulting-agency-switched-from-contentcal-to-vista-social/',
    backgroundFragment: (
      <Fragment>
        <Box position="absolute" bottom="-10px" left="-25px">
          <LocalImage source={imageStory1Image1} />
        </Box>
      </Fragment>
    ),
  },
  {
    storyTitle: 'Why A Leading Agency Switched From Hootsuite to Vista Social',
    title: 'The Venandi Group',
    description: (
      <Text mt="l" color={COLOR_CONSTANTS.DENIM} fontSize="l">
        ”I was using Hootsuite before. It got very complicated to add new account. The cost was expensive when managing
        multiple accounts and also lacked the ability to post Instagram carousels straight away. With Hootsuite, you
        would get a notification on your phone, and have to post the Instagram carousel manually. This was not ideal.
        But Vista Social was one of the first to offer it automatically, and it has honestly saved me so much time!{' '}
        <Text as="span" color="primary" fontSize="l">
          Will be using Vista Social for the rest of my working career!
        </Text>
        ”
      </Text>
    ),
    person: {
      logo: imageStory2Person,
      name: 'Joshua Hunter-Atencia',
      position: `Founder of the Venandi Group`,
      count: 5,
    },
    companyLogo: imageStory2Logo,
    readCaseStudyUrl: 'https://vistasocial.com/insights/why-a-leading-agency-switch-from-hootsuite-to-vista-social/',
    backgroundFragment: (
      <Fragment>
        <Box position="absolute" bottom="80px" left="-60px">
          <LocalImage source={imageStory2Image1} />
        </Box>
        <Box position="absolute" top="170px" right="-95px">
          <LocalImage source={imageStory2Image2} />
        </Box>
      </Fragment>
    ),
  },
  {
    storyTitle: 'How a Leading Production Company Delivers Content at Scale with Vista Social',
    title: 'Acequia',
    description: (
      <Text mt="l" color={COLOR_CONSTANTS.DENIM} fontSize="l">
        ”
        <Text as="span" color="primary" fontSize="l">
          The Vista Social dashboard is very intuitive
        </Text>
        , with very useful features like the reports section. The Universal Mentions is another great feature we really
        love to use.” Since video is a strong focus for Acequia, they wanted a way to streamline their social media
        scheduling and publishing to have more time to engage and better connect with their audience.
      </Text>
    ),
    person: {
      logo: imageStory3Person,
      name: `Francisco Erian`,
      position: `Digital Content at Acequia`,
      count: 5,
    },
    companyLogo: imageStory3Logo,
    readCaseStudyUrl:
      'https://vistasocial.com/insights/how-a-leading-production-company-delivers-content-at-scale-with-vista-social/',
    backgroundFragment: (
      <Fragment>
        <Box position="absolute" bottom="-16px" left="-60px">
          <LocalImage source={imageStory3Image1} />
        </Box>
        <Box position="absolute" bottom="-16px" right="0">
          <LocalImage source={imageStory3Image2} />
        </Box>
      </Fragment>
    ),
  },
]

const FeaturedStoriesSection = () => {
  return (
    <Wrapper bg={COLOR_CONSTANTS.SALT}>
      <Container
        my="xxl"
        pl={{ mobile: 'l', tablet: 'l' }}
        pr={{ mobile: 'l', tablet: 'l' }}
        position="relative"
        maxWidth={CONTAINER_MAX_WIDTH}
        width="100%"
        height="100%"
      >
        <H3 fontSize="6xl" fontWeight="bold" color={COLOR_CONSTANTS.WHITE} textAlign="center" mb="xxl">
          Featured Stories
        </H3>
        {STORIES.map(
          ({ storyTitle, title, description, person, companyLogo, readCaseStudyUrl, backgroundFragment }) => (
            <Flex flexDirection="column" mb="xxl" pb="l" position="relative">
              <Flex maxWidth="685px" zIndex="1" flexDirection="column">
                <Text color="white" fontSize="xxxl" textAlign="left">
                  {storyTitle}
                </Text>
                <Flex mt="m" justifyContent="flex-end" alignItems="center" display={{ mobile: 'flex', tablet: 'none' }}>
                  <StyledReadCaseStudyText
                    as="a"
                    href={readCaseStudyUrl}
                    fontWeight="medium"
                    fontSize="l"
                    color={COLOR_CONSTANTS.WHITE}
                  >
                    READ CASE STUDY
                  </StyledReadCaseStudyText>
                  <LocalImage src={carouselDoubleArrowRightIcon} />
                </Flex>
              </Flex>
              <StyledStoryWrapper mt="l">
                <Text fontWeight="bold" fontSize="3xl" color={COLOR_CONSTANTS.DENIM}>
                  {title}
                </Text>
                {description}
                <Flex mt="l" justifyContent="space-between" flexDirection={{ mobile: 'column', mobileLarge: 'row' }}>
                  <Flex alignItems="center">
                    <StyledStoryPersonImage
                      source={person.logo}
                      width={{ mobile: '60px', mobileLarge: '100px' }}
                      height={{ mobile: '60px', mobileLarge: '100px' }}
                    />
                    <Flex flexDirection="column" ml="l">
                      <Text color="secondaryText" fontWeight="bold" fontSize="l">
                        {person.name}
                      </Text>
                      <Text color="secondaryText" mt="xs">
                        {person.position}
                      </Text>
                      <ReactStars
                        count={5}
                        size={24}
                        color2={colors.primary}
                        color1="rgba(0, 99, 227, 0.2)"
                        value={person.count}
                        edit={false}
                        half={false}
                      />
                    </Flex>
                  </Flex>
                  <Flex alignItems="center" display={{ mobile: 'none', tablet: 'flex' }}>
                    <LocalImage
                      source={companyLogo}
                      height="80px"
                      maxWidth={{ mobileLarge: '250px', tablet: 'auto' }}
                    />
                  </Flex>
                </Flex>
              </StyledStoryWrapper>
              {backgroundFragment}
              <Flex
                position="absolute"
                width="270px"
                height="270px"
                bg="primary"
                borderRadius={radius.pill}
                top="-75px"
                right="-45px"
                zIndex="0"
                alignItems="center"
                justifyContent="center"
                display={{ mobile: 'none', tablet: 'flex' }}
              >
                <StyledReadCaseStudyText
                  as="a"
                  href={readCaseStudyUrl}
                  fontWeight="medium"
                  fontSize="l"
                  color={COLOR_CONSTANTS.SALT}
                  target="_blank"
                >
                  READ CASE STUDY
                </StyledReadCaseStudyText>
                <LocalImage src={carouselDoubleArrowRightIcon} />
              </Flex>
            </Flex>
          )
        )}
      </Container>
    </Wrapper>
  )
}

export default FeaturedStoriesSection
